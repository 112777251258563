<template>
    <div class="shop_main noMenu">
        <div style="background: #FFF">
            <menuCar  type="3"/>
        </div>

        <div class="shop_carConfirm pb0" style="background: #FFF">
            <div class="shop_carUpload_header">
                <span class="no" style="display: flex; flex-direction: row; align-items: center"><span class="text">订单提交成功，请尽快支付，</span> 订单号：
                    <div style="width: 600px; overflow: hidden; display: flex; flex-direction: row; align-items: center; text-overflow: ellipsis">
                        <span class="mr32" v-for="(item, index) in listData" :key="index">{{item.detailCode}}</span>
                    </div></span>
                <span class="price">应付总金额： ¥ {{order.price}}</span>
            </div>

            <div class="shop_carUpload_info">
                <div class="item">
                    <span class="text">请您在<span class="num">5天</span>内完成支付，否则订单将自动取消！</span>
<!--                    <a href="" class="link">订单详情<i class="el-icon-caret-bottom"></i></a>-->
                </div>
                <span class="text"> 收货地址：{{order.userProvince}} {{order.userCity}} {{order.userArea}} {{order.userAddress}}<span class="ml30">收货人：{{order.userName}} {{order.userPhone}}</span></span>
            </div>

            <div class="shop_carUpload_list">
                <div class="header">
                    <div class="item" style="width: 160px"><span class="text">订单类型/编号</span></div>
                    <div class="item" style="flex: 1"><span class="text">订单商品</span></div>
                    <div class="item" style="width: 140px; text-align: center"><span class="text">订单金额</span></div>
                    <div class="item" style="width: 140px; text-align: center"><span class="text">下单时间</span></div>
                    <div class="item" style="width: 100px; text-align: center"><span class="text">状态</span></div>
<!--                    <div class="item" style="width: 100px; text-align: center"><span class="text">操作</span></div>-->
                </div>

                <div class="goodsItem" v-for="(item, index) in listData" :key="index">
                    <div class="store">
                        <img src="../../../assets/img/shop_order.png" />
                        <span>{{item.goodsInfo[0].storeName}}</span>
                    </div>

                    <div class="list">
                        <div class="goods">
                            <div class="item" style="width: 160px">
                                <span class="text">{{item.detailCode}}</span>
                            </div>
                            <div class="item image" style="flex: 1; justify-content: flex-start">
                                <el-image v-for="(good, i) in item.goodsInfo" :key="i" :src="imageURL + good.goodsImageUrl" fit="contain"></el-image>
                            </div>
                            <div class="item" style="width: 140px; align-items: center">
                                <span class="text">¥ {{item.price}}</span>
                                <span class="text" v-if="order.payWay === 1">直接打款</span>
                                <span class="text" v-if="order.payWay === 2">线下支付</span>
                            </div>
                            <div class="item" style="width: 140px; align-items: center">
                                <span class="text">{{item.createdAt}}</span>
                            </div>
                            <div class="item" style="width: 100px; align-items: center">
                                <span class="status red">待支付</span>
                            </div>
                        </div>

                        <!--<div class="goods footer" v-if="order.payWay === 2">
                            <div class="item" style="align-items: flex-start; width: 100%; padding: 0">
                                <span class="text" style="margin-bottom: 15px;letter-spacing: 1px;">请向以下账户支付货款</span>

                                <div class="accountList">
                                    <div class="accountItem">
                                        <p>收款账户名称：<span>中国银联股份有限公司黑龙江</span></p>
                                        <p>收款账号：<span>558801000034718</span></p>
                                        <p>开户行：<span>营口银行股份有限公司哈尔滨分行</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>-->

                        <div class="goods footer" v-if="order.payWay === 2">
                            <div class="item" style="align-items: flex-start; width: 100%">
                                <span class="text" style="margin-bottom: 15px;letter-spacing: 1px;">上传付款凭证</span>
                                <el-upload
                                        :action="uploadURL"
                                        :before-upload="beforeUploadImage"
                                        :on-success=" (res) => imageUrlUpload(index, res)"
                                        :on-remove=" (file, fileList) => imageUrlUploadRemove(index, file, fileList)"
                                        list-type="picture-card"
                                        class="avatar">
                                    <i class="el-icon-plus"></i>
                                </el-upload>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="shop_carConfirm_btn" style="justify-content: center" v-if="order.payWay === 2">
                    <el-button type="primary" style="width: 108px" :loading="isSubmitLoading" @click="submitForm">交易完成</el-button>
                </div>
            </div>
        </div>

        <div class="confirm_pay_main" v-if="order.payWay === 1 && payType !== 4">
            <div class="pay_price" v-if="false">
                <span class="text">付款金额：</span>
                <el-input v-model="input" placeholder="付款金额"></el-input>
                <span class="text">元（剩余未付金额<span>¥195.87</span>）</span>
            </div>

            <div class="pay_main">
                <div class="title">
                    <h1>请选择以下方式付款</h1>
                </div>

                <div class="pay_type">
                    <div class="header">
                        <a href="javascript:void(0)" :class="{on: payType === 1}" @click="payType = 1">快捷支付</a>
                        <a href="javascript:void(0)" :class="{on: payType === 2}" @click="payType = 2">个人网银支付</a>
                        <a href="javascript:void(0)" :class="{on: payType === 3}" @click="payType = 3">企业网银支付</a>
                    </div>

                    <div class="pay_quick" v-show="payType === 1">
                        <div v-for="(list, i) in cardList" :key="i">
                            <div class="item" v-for="(item, j) in list.bankCardList" :key="j" :class="{on: Number(changeCardId) === Number(item.id)}" @click="changeCardId = item.id, changeCardType = list.paymentCode">
                                <img src="../../../assets/img/change_card_default.png" class="icon default" />
                                <img src="../../../assets/img/change_card_on.png" class="icon on" />
                                <img :src="imageURL + item.cardCode + '.png'" class="img" />
                                <div class="name">{{item.bankName}}</div>
                                <div class="number">{{Number(item.cardType === 1) ? '储蓄卡' : '信用卡'}}（{{bankNuLast(item.cardNo)}}）</div>
                            </div>


                            <div class="addCarBox">
                                <a href="javascript:void(0)" class="addCar" @click="toShowAddCardPopup(list)">
                                    <img src="../../../assets/img/card_add.png" class="icon" />绑定新卡
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="pay_net" v-show="payType === 2">
                        <div class="list" v-for="(list, i) in onlineBankPerson" :key="i">
                            <div class="item" v-for="(item, j) in list.bankList" :key="j" :class="{on: Number(onlineBankPersonInfo.paymentBankId) === Number(item.id)}" @click="onlineBankPersonInfo.paymentBankId = item.id, onlineBankPersonInfo.payType = list.paymentCode, onlineBankPersonInfo.payNumber = list.paymentNumber">
                                <img :src="imageURL + item.url" class="img" />{{item.name}}<img src="../../../assets/img/tabChange.png" class="bg" />
                            </div>
                        </div>
                    </div>

                    <div class="pay_net" v-show="payType === 3">
                        <div class="list" v-for="(list, i) in onlineBankFirm" :key="i">
                            <div class="item" v-for="(item, j) in list.bankList" :key="j" :class="{on: Number(onlineBankFirmInfo.paymentBankId) === Number(item.id)}" @click="onlineBankFirmInfo.paymentBankId = item.id, onlineBankFirmInfo.payType = list.paymentCode, onlineBankPersonInfo.payNumber = list.paymentNumber">
                                <img :src="imageURL + item.url" class="img" />{{item.name}}<img src="../../../assets/img/tabChange.png" class="bg" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="pay_password" v-show="payType === 1">
                    <div class='am_payPwd' id="ids_password">
                        <input type="password" name="password" style="position: fixed; z-index: -999999; width: 0; height: 0"/>
                        <input type="password"
                               maxlength="1"
                               autocomplete="off"
                               @input="changeInput"
                               @click="changePwd"
                               @keyup="keyUp($event)"
                               @keydown="oldPwdList = pwdList.length"
                               class="shortInput"
                               v-for="(v, i) in 6" :key="i"
                               v-model="pwdList[i]" />

                        <input  style="position: fixed; z-index: -999999; width: 0; height: 0"/>
                    </div>

                    <div class="desc">请输入6位数字支付密码</div>

                    <el-button type="primary" style="width: 206px" :loading="isSubmitLoading"  @click="toChangeCardPay">立即支付</el-button>
                </div>

                <div class="pay_net_btn" v-show="payType === 2 || payType === 3">
                    <el-button type="primary" style="width: 206px" :loading="isSubmitLoading" @click="toPayOnline">立即支付</el-button>
                </div>
            </div>

            <div class="pay_else">
                <div class="item" v-for="(item, i) in gatewayList" :key="i" @click="toGatewayPay(item)">
                    <img :src="imageURL + item.logo" class="img" width="44" height="44" />{{item.paymentType}}
                </div>
            </div>
        </div>

        <div class="confirm_pay_code" v-if="payType === 4">
            <div class="title">
                <h1>{{gatewayListInfo.paymentType}}</h1>
            </div>

            <div class="pay_detail">
                <div class="desc" v-if="Number(seconds) > 0">距离二维码过期还剩<span>{{seconds}}</span>秒，过期后点击二维码完成刷新，重新获取二维码</div>
                <div class="desc" v-else>二维码已过期,点击二维码完成刷新，重新获取二维码</div>

                <div class="code_detail">
                    <div class="code_info">
                        <div class="code" v-loading="isSubmitLoading" @click="toGatewayPaySend">
<!--                            <img :src="'./img/banner_about.png'" class="img" />-->
                            <vue-qr :text="codeText" :size="264" :margin="0" colorDark="#000000" colorLight="#fff" :logoScale="0.2" :callback="codeDownload"></vue-qr>
                        </div>

                        <div class="code_desc">
                            <img src="../../../assets/img/sys.png" class="icon" />
                            <div class="text">请使用{{gatewayListInfo.paymentType}}<br />扫描二维码支付</div>
                        </div>
                    </div>

<!--                    <img src="../../../assets/img/wechat_bg.png" class="bg" />-->
                </div>
            </div>

            <div class="go_link">
                <a href="javascript:void(0)" @click="toChangeElsePay">&#60;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;选择其他支付方式</a>
            </div>
        </div>

        <div class="confirm_pay_code" v-if="false">
            <div class="title">
                <h1>支付宝支付</h1>
            </div>

            <div class="pay_detail">
                <div class="desc">距离二维码过期还剩<span>58</span>秒，过期后点击二维码完成刷新，重新获取二维码</div>

                <div class="code_detail">
                    <div class="code_info">
                        <div class="code">
                            <img :src="'./img/banner_about.png'" class="img" />
                        </div>

                        <div class="code_desc">
                            <img src="../../../assets/img/sys.png" class="icon" />
                            <div class="text">打开手机支付宝<br />扫一扫继续付款</div>
                        </div>
                    </div>

                    <div class="alipay_bg">
                        <img src="../../../assets/img/alipay_icon.png" class="icon01" />
                        <img src="../../../assets/img/alipay_bg.png" class="icon02" />
                    </div>
                </div>
            </div>

            <div class="go_link">
                <a href="javascript:void(0)">&#60;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;选择其他支付方式</a>
            </div>
        </div>

        <el-dialog title="快捷支付" :visible.sync="isPopupAddCard" width="724px" :close-on-click-modal="false" class="popupPay">
            <el-form ref="addCardBinForm" :model="addCardBinForm" :rules="rules" size="small" label-width="76px" class="cardForm" v-if="addCardType === 1">
                <el-form-item label="持卡人" prop="bankUser">
                    <el-input v-model="addCardBinForm.bankUser" placeholder="请输入持卡人姓名"></el-input>
                </el-form-item>
                <el-form-item label="身份证" prop="cardNumber">
                    <el-input v-model="addCardBinForm.cardNumber" placeholder="请输入持卡人身份证号"></el-input>
                </el-form-item>
                <el-form-item label="卡号" prop="cardNo">
                    <el-input v-model="addCardBinForm.cardNo" placeholder="请输入本人银行卡"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" :loading="isSubmitLoading" @click="toAddCardBin('addCardBinForm')">验证银行卡</el-button>
                </el-form-item>
            </el-form>

            <el-form ref="addCardCheckForm" :model="addCardCheckForm" :rules="rules" size="small" label-width="66px" class="cardForm" v-if="addCardType === 2">
                <el-form-item label="持卡人">
                    <span class="text">{{addCardCheckForm.bankUser}}</span>
                </el-form-item>
                <el-form-item label="身份证">
                    <span class="text">{{addCardCheckForm.cardNumber}}</span>
                </el-form-item>
                <el-form-item label="卡号">
                    <span class="text">{{addCardCheckForm.cardNo}}</span>
                </el-form-item>
                <el-form-item label="卡类型">
                    <span class="text">{{addCardCheckForm.bankName}} ｜ {{Number(addCardCheckForm.cardType) === 1 ? '储蓄卡' : '信用卡'}}</span>
                </el-form-item>

                <el-form-item label="cvv2" prop="cvn2" v-if="Number(addCardCheckForm.cardType) === 2">
                    <el-input v-model="addCardCheckForm.cvn2" placeholder="请输入信用卡安全码"></el-input>
                </el-form-item>

                <el-form-item label="有效期" prop="expy" v-if="Number(addCardCheckForm.cardType) === 2">
                    <el-input v-model="addCardCheckForm.expy" placeholder="请输入信用卡有效期（格式MMyy）"></el-input>
                </el-form-item>

                <el-form-item label="手机" prop="mobile">
                    <el-input v-model="addCardCheckForm.mobile" placeholder="银行预留手机号"></el-input>
                </el-form-item>

                <el-form-item label="验证码" prop="code">
                    <el-input v-model="addCardCheckForm.code" placeholder="短信验证码" class="code"></el-input>
                    <el-button type="default" class="codeBtn" :loading="isRequestSend" :disabled="isSendCode" @click="toSendCode">{{isSendCode ? smsBtnText : '获取验证码'}}</el-button>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" :loading="isSubmitLoading" @click="toAddCardPay('addCardCheckForm')">绑卡并支付</el-button>
                </el-form-item>
            </el-form>

            <el-form ref="passwordForm" :inline="true" :model="passwordForm" :rules="rules" size="small" class="cardForm inline" v-if="addCardType === 3">
                <el-form-item label="支付密码" prop="payPassword">
                    <el-input v-model="passwordForm.payPassword" max show-password placeholder="请输入支付密码"></el-input>
                </el-form-item>
                <el-form-item label="再次输入，确认支付密码" prop="passwordAgent">
                    <el-input v-model="passwordForm.passwordAgent" show-password placeholder="请再次输入支付密码"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" :loading="isSubmitLoading" @click="toSetPassword('passwordForm')">绑卡并支付</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <el-dialog title="支付中" :visible.sync="isPopupPay" width="462px" :close-on-click-modal="false" class="popupPay">
            <div class="pay_notice">
                <span class="text">请在新打开的页面进行支付，支付完成前请不要关闭该窗口</span>

                <div class="btnGroup">
                    <el-button type="primary" @click="$router.replace('/shop/car/success')">支付已完成</el-button>
                    <el-button type="default" @click="isPopupPay = false">取消支付</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
  import {imageURL, uploadURL, payReturnUrl} from "../../../utils/config";
  import {beforeUploadImage, bankNuLast} from "../../../utils/method";
  import {putOrder, channelList, postPaymentCardBin, postPaymentSendCode, postPaymentBindPay, postPayPassword, postPaymentChangePay, postPaymentOnLine, postPaymentGateway, getPayStatus} from '../../../api/shop/car';
  import md5 from 'js-md5';
  import VueQr from 'vue-qr';

  const menuCar = () => import('./menu');
  export default {
    name: "shopHelp",
    data () {
      let validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入支付密码'));
        } else if (value !== this.passwordForm.payPassword) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
        bankNuLast,
        order: {},
        listData: [],
        imageURL: imageURL,
        uploadURL: uploadURL,
        beforeUploadImage: beforeUploadImage,
        pwdList: [],
        oldPwdList: [],
        isDelete: false,
        ipt: '',
        isPopupAddCard: false,
        addCardType: 1,
        addCardBinForm: {
          bankUser: '',
          cardNumber: '',
          cardNo: '',
          channelId: '',
          paymentCode: '',
          paymentNumber: ''
        },
        addCardCheckForm: {
          bankUser: '',
          cardNumber: '',
          cardNo: '',
          channelId: '',
          paymentCode: '',
          paymentNumber: '',
          bankCode: '',
          bankName: '',
          cardType: 1,
          cvn2: '',
          expy: '',
          mobile: '',
          sn: '',
          code: ''
        },
        passwordForm: {
          payPassword: '',
          passwordAgent: ''
        },
        isPopupPay: false,
        input: '',
        onlineBankPerson: [],
        onlineBankFirm: [],
        cardList: [],
        gatewayList: [],
        payType: 1,
        isSearchLoading: false,
        isSubmitLoading: false,
        isRequestSend: false,
        isSendCode: false,
        smsBtnText: '',
        rules: {
          bankUser: [
            {required: true, message: '请输入持卡人姓名', trigger: 'blur'}
          ],
          cardNumber: [
            {required: true, message: '请输入持卡人身份证号', trigger: 'blur'}
          ],
          cardNo: [
            {required: true, message: '请输入本人银行卡', trigger: 'blur'}
          ],
          cvn2: [
            {required: true, message: '请输入信用卡安全码', trigger: 'blur'}
          ],
          expy: [
            {required: true, message: '请输入信用卡有效期', trigger: 'blur'}
          ],
          mobile: [
            {required: true, message: '请输入银行预留手机号', trigger: 'blur'}
          ],
          payPassword: [
            {required: true, message: '请输入支付密码', trigger: 'blur'},
            { min: 6, max: 6, message: '请输入6位支付密码', trigger: 'blur' }
          ],
          passwordAgent: [
            { required: true, validator: validatePass, trigger: 'blur'}
          ],
        },
        changeCardId: '',
        changeCardType: '',
        onlineBankPersonInfo: {
          payType: '',
          paymentBankId: '',
          payNumber: ''
        },
        onlineBankFirmInfo: {
          payType: '',
          paymentBankId: '',
          payNumber: ''
        },
        gatewayListInfo: {
          channelId: '',
          payNumber: '',
          payType: '',
          payWay: '',
          paymentType: ''
        },
        codeText: '',
        codeId: '',
        timer: null,
        seconds: 60
      }
    },
    created() {
      this.$store.commit('changeScrollTop');
      this.init()
    },
    mounted () {
      this.ipt = document.querySelectorAll('#ids_password .shortInput')
    },
    destroyed() {
      clearInterval(this.timer)
    },
    methods: {
      init () {
        let data = JSON.parse(sessionStorage.getItem('uploadOrderData'));
        data.orderDetails.forEach(item => {
          item.url = []
        });
        this.listData = data.orderDetails;
        this.order = data.order;
        if (this.order.payWay === 1) {
          this.getChannelList()
        }
      },
      getChannelList () {
        channelList({
          data:{
            userType: '1'
          }
        }).then(res=>{
          if(res.code===0){
            let onlineBankPerson = [];
            let onlineBankFirm = [];
            let cardList = [];
            let gatewayList = [];

            res.data.forEach(item => {
              if (item.paymentNumber === 1) {
                onlineBankPerson.push(item)
              } else if (item.paymentNumber === 2) {
                onlineBankFirm.push(item)
              } else if (item.paymentNumber === 3) {
                cardList.push(item)
              } else {
                gatewayList.push(item)
              }
            })

            this.onlineBankPerson = onlineBankPerson
            this.onlineBankFirm = onlineBankFirm
            this.cardList = cardList
            this.gatewayList = gatewayList
          }
        })
      },
      imageUrlUpload (index, res) {
        if (res.code === 0) {
          this.listData[index].url.push(res.data)
        }
      },
      imageUrlUploadRemove (index, file, fileList) {
        let data = []
        fileList.forEach(item => {
          data.push(item.response.data)
        })
        this.listData[index].url = data
      },
      submitForm () {
        // let isError = false
        let data = []
        this.listData.forEach(item => {
          data.push({
            id: item.id,
            url: item.url
          })

          // if (item.url.length === 0) {
          //   isError = true
          // }
        })

        // if (isError) {
        //   this.$message({
        //     message: '请上传支付凭证',
        //     type: 'warning',
        //     showClose: true,
        //   });
        //   return false
        // }

        this.isSubmitLoading = true
        putOrder({
          data: {
            detailsInfo: data,
            status: 2
          }
        }).then(res => {
          this.isSubmitLoading = false
          if (res.code === 0) {
            this.$router.replace('/shop/car/success')
          }
        }).catch(() => {
          this.isSubmitLoading = false
        })
      },
      keyUp (ev) {
        let index = this.pwdList.length
        if (!index) return
        if (ev.keyCode === 8) {
          this.isDelete = true
          if (this.oldPwdList === this.pwdList.length) {
            if (index === this.pwdList.length) {
              this.pwdList.pop()
            }
            index--
          } else {
            index > 0 && index--
          }
          this.ipt[index].focus()
        } else if (this.isDelete && index === this.pwdList.length && /^\d$/.test(ev.key)) {
          this.isDelete = false
          this.pwdList.pop()
          this.pwdList.push(ev.key)
          this.ipt[this.pwdList.length] && this.ipt[this.pwdList.length].focus()
        }
        // this.$emit('getPwd', this.pwdList.join(''))
      },
      changePwd () {
        let index = this.pwdList.length;
        index === 6 && index--;
        this.ipt[index].focus();
        // this.ipt[index].type = 'password'
      },
      changeInput () {
        let index = this.pwdList.length
        let val = this.pwdList[index - 1]
        if (!/[0-9]/.test(val)) {
          this.pwdList.pop()
          return
        }
        if (!val) {
          this.pwdList.pop()
          index--
          if (index > 0) this.ipt[index - 1].focus()
        } else {
          if (index < 6) this.ipt[index].focus()
        }
      },
      toShowAddCardPopup (list) {
        this.addCardType = 1;
        this.addCardBinForm.channelId = list.id;
        this.addCardBinForm.paymentCode = list.paymentCode;
        this.addCardBinForm.paymentNumber = list.paymentNumber;
        this.isPopupAddCard = true;
      },
      toAddCardBin (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.isSubmitLoading = true;
            postPaymentCardBin({
              data: {
                bankUser: this.addCardBinForm.bankUser,
                cardNumber: this.addCardBinForm.cardNumber,
                cardNo: this.addCardBinForm.cardNo,
              }
            }).then(res => {
              this.isSubmitLoading = false;
              if (res.code === 0) {
                this.addCardCheckForm.bankUser = this.addCardBinForm.bankUser;
                this.addCardCheckForm.cardNumber = this.addCardBinForm.cardNumber;
                this.addCardCheckForm.cardNo = this.addCardBinForm.cardNo;
                this.addCardCheckForm.channelId = this.addCardBinForm.channelId;
                this.addCardCheckForm.paymentCode = this.addCardBinForm.paymentCode;
                this.addCardCheckForm.paymentNumber = this.addCardBinForm.paymentNumber;
                this.addCardCheckForm.bankCode = res.data.bankCode;
                this.addCardCheckForm.bankName = res.data.bankName;
                this.addCardCheckForm.cardType = res.data.cardType;
                this.addCardType = 2
              }
            }).catch(() => {
              this.isSubmitLoading = false;
            })
          }
        })
      },
      toSendCode () {
        if (Number(this.addCardCheckForm.cardType) === 2) {
          if (this.addCardCheckForm.cvn2 === '') {
            this.$message({
              message: '请输入信用卡安全码',
              type: 'warning',
              showClose: true,
            });
            return false
          }
          if (this.addCardCheckForm.expy === '') {
            this.$message({
              message: '请输入信用卡有效期',
              type: 'warning',
              showClose: true,
            });
            return false
          }
        }
        if (this.addCardCheckForm.mobile === '') {
          this.$message({
            message: '请输入银行预留手机号',
            type: 'warning',
            showClose: true,
          });
          return false
        }

        this.isRequestSend = true;
        postPaymentSendCode({
          data: {
            channelId: this.addCardCheckForm.channelId,
            payType: this.addCardCheckForm.paymentCode,
            payNumber: this.addCardCheckForm.paymentNumber,
            cardCode: this.addCardCheckForm.bankCode,
            bankName: this.addCardCheckForm.bankName,
            bankUser: this.addCardCheckForm.bankUser,
            cardNo: this.addCardCheckForm.cardNo,
            cardNumber: this.addCardCheckForm.cardNumber,
            cvn2: this.addCardCheckForm.cvn2,
            expy: this.addCardCheckForm.expy,
            mobile: this.addCardCheckForm.mobile,
            payWay: this.order.payWay,
            cardType: this.addCardCheckForm.cardType,
            price: this.order.price,
            shopOrderSn: this.listData[0].detailCode,
            shopOrderSnMain: this.listData[0].ordersCode,
            storeId: this.listData[0].storeId,
            storeName: this.listData[0].storeName,
            ordersId: this.listData[0].id
          }
        }).then(res => {
          this.isRequestSend = false;
          if (res.code === 0) {
            this.isSendCode = true;
            this.toChangeSmsTime(60);
            this.addCardCheckForm.sn = res.data.sn
          }
        }).catch(() => {
          this.isRequestSend = false;
        })
      },
      toChangeSmsTime (time) {
        if (time === 0) {
          this.isSendCode = false
        } else {
          this.isSendCode = true
          this.smsBtnText = '重新获取(' + time + 's)'
          let times = time - 1
          let that = this
          setTimeout(() => {
            that.toChangeSmsTime(times)
          }, 1000)
        }
      },
      toAddCardPay (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.toAddCardPaySend()
          }
        })
      },
      toAddCardPaySend () {
        this.isSubmitLoading = true;
        postPaymentBindPay({
          data: {
            code: this.addCardCheckForm.code,
            sn: this.addCardCheckForm.sn
          }
        }).then(res => {
          this.isSubmitLoading = false;
          if (res.code === 0) {
            this.addCardType = 1;
            this.isPopupAddCard = false;
            this.$router.replace('/shop/car/success')
          } else if (res.code === 500109) {
            this.addCardType = 3;
          } else {
            this.addCardType = 2;
          }
        }).catch(() => {
          this.isSubmitLoading = false;
        })
      },
      toSetPassword (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.isSubmitLoading = true;
            postPayPassword({
              data: {
                payPassword: md5(this.passwordForm.payPassword)
              }
            }).then(res => {
              this.isSubmitLoading = false;
              if (res.code === 0) {
               this.toAddCardPaySend()
              }
            }).catch(() => {
              this.isSubmitLoading = false;
            })
          }
        })
      },
      toChangeCardPay () {
        if (this.changeCardId === '') {
          this.$message({
            message: '请选择银行卡',
            type: 'warning',
            showClose: true,
          });
          return false
        }
        if (this.pwdList.join('') === '') {
          this.$message({
            message: '请输入支付密码',
            type: 'warning',
            showClose: true,
          });
          return false
        }
        this.isSubmitLoading = true;
        postPaymentChangePay({
          data: {
            bankId: this.changeCardId,
            payPassword: md5(this.pwdList.join('')),
            payWay: this.order.payWay,
            price: this.order.price,
            shopOrderSn: this.listData[0].detailCode,
            shopOrderSnMain: this.listData[0].ordersCode,
            storeId: this.listData[0].storeId,
            storeName: this.listData[0].storeName,
            ordersId: this.listData[0].id,
            payType: this.changeCardType,
          }
        }).then(res => {
          this.isSubmitLoading = false;
          if (res.code === 0) {
            this.$router.replace('/shop/car/success')
          }
        }).catch(() => {
          this.isSubmitLoading = false;
        })
      },
      toPayOnline () {
        if (this.payType === 2) {
          if (this.onlineBankPersonInfo.paymentBankId === '') {
            this.$message({
              message: '请选择支付银行',
              type: 'warning',
              showClose: true,
            });
            return false
          }
        }
        if (this.payType === 3) {
          if (this.onlineBankFirmInfo.paymentBankId === '') {
            this.$message({
              message: '请选择支付银行',
              type: 'warning',
              showClose: true,
            });
            return false
          }
        }
        let paymentBankId = '';
        let payNumber = '';

        if (this.payType === 2) {
          paymentBankId = this.onlineBankPersonInfo.paymentBankId;
          payNumber = this.onlineBankPersonInfo.payNumber;
        }

        if (this.payType === 3) {
          paymentBankId = this.onlineBankFirmInfo.paymentBankId;
          payNumber = this.onlineBankFirmInfo.payNumber;
        }

        this.isSubmitLoading = true;
        postPaymentOnLine({
          data: {
            payWay: this.order.payWay,
            paymentBankId: paymentBankId,
            price: this.order.price,
            shopOrderSn: this.listData[0].detailCode,
            shopOrderSnMain: this.listData[0].ordersCode,
            storeId: this.listData[0].storeId,
            storeName: this.listData[0].storeName,
            returnUrl: payReturnUrl,
            payNumber: payNumber,
            ordersId: this.listData[0].id
          }
        }).then(res => {
          this.isSubmitLoading = false;
          if (res.code === 0) {
            this.isPopupPay = true;
            sessionStorage.setItem('onLinePayData', res.data.url);
            const { href } = this.$router.resolve({
              path: "/onLinePay"
            });
            window.open(href, '_blank');
          }
        }).catch(() => {
          this.isSubmitLoading = false;
        })
      },
      toGatewayPay (item) {
        this.gatewayListInfo.channelId = item.id;
        this.gatewayListInfo.payNumber = item.paymentNumber;
        this.gatewayListInfo.payType = item.paymentCode;
        this.gatewayListInfo.paymentType = item.paymentType;
        this.gatewayListInfo.payWay = this.order.payWay;
        this.payType = 4;
        this.codeTimes(60);

        this.toGatewayPaySend()
      },
      toGatewayPaySend () {
        this.isSubmitLoading = true;

        postPaymentGateway({
          data: {
            channelId: this.gatewayListInfo.channelId,
            localUrl: payReturnUrl,
            ordersId: this.listData[0].id,
            payNumber: this.gatewayListInfo.payNumber,
            payType: this.gatewayListInfo.payType,
            payWay: this.gatewayListInfo.payWay,
            price: this.order.price,
            shopOrderSn: this.listData[0].detailCode,
            shopOrderSnMain: this.listData[0].ordersCode,
            storeId: this.listData[0].storeId,
            storeName: this.listData[0].storeName,
          }
        }).then(res => {
          this.isSubmitLoading = false;
          if (res.code === 0) {
            this.codeText = res.data.url;
            this.codeId = res.data.id;
          }
        }).catch(() => {
          this.isSubmitLoading = false;
        })
      },
      codeDownload () {
        this.seconds = 60;
        clearInterval(this.timer);
        this.timer = setInterval(this.getPayStatus, 5000);
      },
      codeTimes (time) {
        if (time > 0) {
          this.seconds--;
          setTimeout(() => {
            this.codeTimes(this.seconds)
          }, 1000)
        }
      },
      getPayStatus () {
        getPayStatus(this.codeId).then(res => {
          if (res.code === 0) {
            if (res.data.status === 1) {
              this.$router.replace('/shop/car/success')
            } else if (res.data.status === -1) {
              this.$message({
                message: '订单支付失败',
                type: 'warning',
                showClose: true,
              });
              this.$router.replace('/shop/order')
            }
          }
        })
      },
      toChangeElsePay () {
        this.payType = 1;
        this.seconds = 0;
        clearInterval(this.timer)
      }
    },
    components: {
      menuCar, VueQr
    }
  }
</script>
